.pageContainer {
  width: 100%;
  margin: 16px 0 72px;
  padding: 0 16px;
  overflow: hidden;
  /* min-height: 100vh; */
}

.pageButton {
  border-radius: 7px !important;
  margin: 10px;
  font-size: 10pt !important;
  font-weight: bold !important;
  width: auto;
}

.pageButtonGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.pageFormContainer {
  width: 100%;
  display: flex;
}

.rs-form-group {
  margin: 0 8px;
}

.pageForm {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pageFlex {
  display: flex;
  justify-content: center;
}

.pageContent {
  width: 100%;
  max-width: 2000px;
  margin-right: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px #00000030;
  background: #ffffff;
  padding: 16px;
  border-radius: 10px !important;
}

.rs-picker-menu {
  z-index: 1001 !important;
}

.pageContent.w650 {
  width: 650px;
  max-width: 100%;
}

.pageContent.w850 {
  width: 850px;
  max-width: 100%;
}

.pageContent.w1050 {
  width: 1050px;
  max-width: 100%;
}

.pageContent.w2000 {
  width: 2000px;
  max-width: 100%;
}

.pageContent.withoutSearch {
  margin-right: 0;
}

.pageLabelAutocomplete {
  margin: 10px 0px 0px 0px;
}

.rs-picker-toggle-value {
  color: #575757 !important;
}

.rs-form-control-wrapper {
  display: block !important;
}

.primaryForm {
  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  align-items: center;
  overflow: hidden;
  padding-right: 5%;
}

.rowAlign {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 700px) {
  .primaryForm {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media (min-width: 700px) {
  .primaryFormContainer {
    min-height: 200px;
  }

  .spliter {
    height: 200px;
    width: 2px;
    background-color: lightgrey;
  }
}

.photoPicker {
  background-color: #f5f5f5;
  height: 205px;
  width: 255px;
  border: 2px dashed lightblue;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.photoPicker:hover {
  opacity: 0.6;
}

.margin-bottom {
  margin-bottom: 16px;
}

#preview {
  height: 200px;
  width: 250px;
}

.rs-input {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uppy-DashboardContent-panelBody {
  max-height: 450px !important;
}

.disabledCheck .rs-checkbox-wrapper {
  cursor: not-allowed !important;
}

.gridInputMask {
  width: 100% !important;
  height: 31px;
  border: none !important;
}

.gridInputMask:active,
.gridInputMask:focus {
  border: none;
  outline: transparent;
}

/* .rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
  margin-bottom: 0px !important;
} */

.columnClick {
  cursor: pointer;
}

.columnClick:hover {
  background-color: #ccc;
}

.divider-flexbox {
  margin-top: 16px;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  padding: 10px;
}

.divider-title {
  font-size: 18px;
  width: 100%;
  margin-bottom: 15px;
  font-weight: bold;
}

.label-retorno {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.15rem;
  background-color: #4caf50;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 4px 0 15px 0 !important;
  padding: 7px 11px;
  width: auto;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.label-retorno-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.15rem;
  background-color: #4caf50;
  border-radius: 5px;
  color: #fff;
  cursor: not-allowed;
  margin: 4px 0 15px 0 !important;
  padding: 7px 11px;
  width: auto;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.3;
}

.input-file[type="file"] {
  display: none;
}

.pageButtonPrimary {
  color: #ffffff !important;
}

.pageButtonSecondary,
.pageButtonPrimary {
  width: auto;
  border-radius: 7px !important;
  margin: 10px;
  font-size: 10pt !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

.pageButtonGroup {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.disabled-text.rs-form-control.rs-form-control-wrapper .rs-input {
  color: #575757;
}

.cancel-modal {
  max-width: 100%;
  position: fixed;
  top: 25%;
}

.addingButton {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
}

.adding-button-content {
  border: 1px solid #ddd;
  padding: 6px;
  border-radius: 4px;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adding-button-content:hover {
  background-color: #f5f5f5;
}