.flexGridInput {
  padding: 0 0.5rem 0 0;
}

.dateInputAlign {
  padding-left: 0.5rem;
}

.subTitle {
  margin: 0.5rem;
}

.labelCorrect {
  margin-top: -1.25rem !important;
}

.labelAlign {
  margin-top: -1.25rem !important;
}

.legendaContainer {
  width: 100%;
  margin: 16px 8px;
  overflow: hidden;
}

.legendaFlex {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.legendaContent {
  width: 100%;
  height: 100%;
  max-width: 2000px;
  margin-bottom: 0;
  background: #ffffffff;
  padding: 8px 15px;
  border-radius: 5px !important;
}

.lista {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
  height: 100%;
  list-style: none;

  color: rgb(54, 54, 170);
}

.addingButton {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
}

.inputDisabled .rs-input:disabled {
  color: #575757;
}

@media (min-width: 1586px) {
  .labelCorrect {
    margin-top: 0 !important;
  }
}

.rs-anim-fade.rs-anim-in.placement-bottom-start.tooltip.rs-tooltip {
  z-index: 1001 !important;
}

.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-tooltip.rs-tooltip {
  z-index: 1001 !important;
}

.input-percent-grid.rs-input-group.rs-input-group-inside.rs-input-group-focus :focus-within {
  box-shadow: none !important;
  -webkit-box-shadow: 0 0 0 3px #fff !important;
}

.input-placeholder ::placeholder {
  color: #333 !important
}

.input-grid .rs-input {
  border: none !important;
  box-shadow: none !important;
}

.formCheckbox.formInputGroup>div>svg {
  width: 20px !important;
  height: 20px !important;
}

.formCheckbox.formInputGroup .formCheckboxText {
  margin-top: 2px;
}