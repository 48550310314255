.text-center {
  text-align: center;
}
.ttu {
  text-transform: uppercase;
}
.printer-ticket {
  display: table !important;
  width: 100%;
  max-width: 400px;
  font-weight: light;
  line-height: 1.3em;
  margin: 0 auto;
}
.printer-ticket,
.printer-ticket * {
  font-family: Tahoma, Geneva, sans-serif;
  font-size: 10px;
}
.printer-ticket th:nth-child(2),
.printer-ticket td:nth-child(2) {
  width: 50px;
}
.printer-ticket th:nth-child(3),
.printer-ticket td:nth-child(3) {
  width: 90px;
  text-align: right;
}
.printer-ticket th {
  font-weight: inherit;
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px dashed #bcbcbc;
}

.printer-ticket tfoot .sup td {
  padding: 10px 0;
  border-top: 1px dashed #bcbcbc;
}
.printer-ticket tfoot .sup.p--0 td {
  padding-bottom: 0;
}
.printer-ticket .title {
  font-size: 1.5em;
  padding: 15px 0;
  font-weight: bold;
}

.container-icon {
  border-radius: 30px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 1s ease;
}
.container-icon:hover {
  background-color: gainsboro;
}
.printer-icon {
  margin: 10px;
}
.printer-ticket td {
  padding-top: 3px;
}
