.datagrid-modal .dx-row:hover {
  background-color: #ccc !important;
  cursor: pointer;
}

.datagrid-modal .dx-row.dx-header-row:hover {
  background-color: #fff !important;
}

.datagrid-modal {
  max-height: 400px;
}
